
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'

export default defineComponent({
  components: {
    DashboardSection,
    CustomButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const userId = route.params.id
    const studioId = route.params.studioId
    const userDetails = computed(() => store.state.client.clientDetails)
    return { userDetails, userId, studioId }
  }
})
