<template>
  <DashboardSection>
    <template #top-right-button>
      <CustomButton
        @onClick="$router.push({ name: studioId ? 'EditClientDetails' : 'EditClient', params: { id: userId } })"
        iconClass="edit"
        label="Edit details"
        color="grey"
      />
    </template>
    <template #content v-if="userDetails">
      <div class="board">
        <div class="board__section">
          <span class="board__section-heading">Company name</span>
          <p>{{ userDetails.name }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Company number</span>
          <p>{{ userDetails.legalId }}</p>
        </div>
        <div class="board__section">
          <span class="board__section-heading">Registered address</span>
          <p>{{ userDetails.address }}</p>
        </div>

        <div class="board__section">
          <span class="board__section-heading">Mailing address</span>
          <p>{{ userDetails.mailingAdress !== null ? userDetails.mailingAdress : userDetails.address }}</p>
        </div>

        <div class="board__section">
          <span class="board__section-heading">Client contact</span>
          <p>{{ userDetails.leadContactName }}</p>
          <p>{{ userDetails.leadContactEmail }}</p>
          <p>{{ userDetails.leadContactPhone }}</p>
        </div>

        <div
          class="board__section"
          v-if="
            userDetails.financeContactName !== '' ||
            userDetails.financeContactEmail !== '' ||
            userDetails.financeContactPhone !== ''
          "
        >
          <span class="board__section-heading">Finance contact</span>
          <p>{{ userDetails.financeContactName }}</p>
          <p>{{ userDetails.financeContactEmail }}</p>
          <p>{{ userDetails.financeContactPhone }}</p>
        </div>
        <div
          class="board__section"
          v-if="
            userDetails.procurementContactName !== '' ||
            userDetails.procurementContactEmail !== '' ||
            userDetails.procurementContactPhone !== ''
          "
        >
          <span class="board__section-heading">Procurement contact</span>
          <p>{{ userDetails.procurementContactName }}</p>
          <p>{{ userDetails.procurementContactEmail }}</p>
          <p>{{ userDetails.procurementContactPhone }}</p>
        </div>
      </div>
    </template>
  </DashboardSection>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'

export default defineComponent({
  components: {
    DashboardSection,
    CustomButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const userId = route.params.id
    const studioId = route.params.studioId
    const userDetails = computed(() => store.state.client.clientDetails)
    return { userDetails, userId, studioId }
  }
})
</script>
<style lang="sass" scoped>
.board
  display: grid
  grid-template-columns: 18rem 18rem
  grid-gap: 16px
  @media( max-width: $ms-breakpoint)
    height: auto
    display: flex
    flex-wrap: wrap
  &__section
    display: inline-block
    width: 100%
    background-color: $white
    padding: 1rem
    border-radius: 4px
    @media( max-width: $ms-breakpoint)
      width: 100%
  &__section-heading
    font-weight: 700
</style>
